import { CargaHorariaServTer } from "./cargaHorariaServTer";
import { ServicoPrestadoPf } from "./servicoPrestadoPF";
import { ContratosPj } from "./contratosPJ";

export class PrestadorServTer {
    public id: number;
    public empresaCnpjCpf: String;
    public empresaCnpjOsc: String;
    public isCPF: boolean;
    public empresaRazaoSocial: string;
    public empresaNomeFantasia: string;
    public empresaAtividadePrincipal: String;
    public empresaStatus: String;
    public empresaEndereco: String;
    public empresaCep: String;


    public empresaEnderecoOsc: String;
    public empresaCepOsc: String;    
    public empresaInscricaoMunicipal: String;
    public numeroInscricaoRGPS: number;
    public numeroRG: string;
    public orgaoEmissor: string;
    public numeroRegistroProfissional: string;
    public orgaoFiscalizador:string;
    public numeroCodigoBrasileiroOcupacao:number;
    public empresaRazaoSocialOsc: string;

    public contratosPj: Array<ContratosPj>;
    public idsContratosPjAExcluir: Array<number>;
    public servicosPrestadosPf: Array<ServicoPrestadoPf>;
    public idsServicosPrestadosPfAExcluir: Array<number>;

    public operacaoData: Date;
    public usuarioOperacao: string;
}

