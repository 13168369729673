import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient, HttpResponse, HttpHeaders } from '@angular/common/http';
import { ParceriaInfo } from '../modulo2/detalheParceria/parceriaInfo';
import { PessoaInfo } from "../modulo2/detalheParceria/pessoaInfo";
import { ParMonitoraPrestaExcecaoTrava } from '../trava/monitoraPrestaExcecaoTrava/monitoraPrestaExcecaoTrava';
import { ExcecaoTravaAnalise } from '../modulo3/excecaoTravaAnalise/excecaoTravaAnalise';
import { ZPagamentoParcerias } from '../modulo1/valoresPagos/zPagamentoParcerias';
import { ExcecaoTravaFrequencia } from '../modulo2/travaFrequencia/excecaoTravaFrequencia';
import { NFSe } from '../modulo2/detalheParceria/nfse';
import { DevolucaoAplicacao } from '../modulo2/detalheParceria/devolucaoRecursos/devolucaoAplicacao';
import { DespesaParceria } from '../modulo2/detalheParceria/demonstrativoDesembolso/despesaParceria';
import { ParceriaMonitoraAvalia } from '../modulo3/monitoramentoAvalicao/parceriaMonitoraAvalia';
import { ParceriaPrestaContaParcial } from '../modulo2/prestacaoDeContasParcial/parceriaPrestaContaParcial';
import { ParceriaPrestaContaFinal } from '../modulo3/prestacaoDeContasFinal/parceriaPrestaContaFinal';
import { Colaborador } from '../modulo2/colaboradores/colaborador';
import { NFe } from '../modulo2/detalheParceria/demonstrativoDesembolso/nfe';
import { Notificacao } from '../notificacoes/notificacao';
import { NotificacaoSininho } from '../notificacoes/notificacaoSininho';
import { NotificacaoTela } from '../notificacoes/notificacaoTela';
import { ParametrosAuditoria } from '../auditoria/parametrizacao/parametrosAuditoria';
import { DocumentosStatusTotais } from '../auditoria/graficos/documentosStatusTotais';
import { PrestacaoDeContas } from '../modulo2/detalheParceria/prestacaoDeContas/prestacaoDeContas';
import { PrestadorServTer } from '../cadastroServicoTerceiros/prestadorServTer';
import { catchError, timeout } from 'rxjs/operators';
import { throwError } from 'rxjs/internal/observable/throwError';

const headers = new HttpHeaders().set('Content-Type', 'text/plain; charset=utf-8')
const httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable()
export class DetalheParceriaApi {

    constructor(private http: HttpClient) {
    }

    getParceriaInfo(id) {
        return this.http.get<ParceriaInfo>(`${environment.serverHost}/secure/${environment.realm}/parcerias?id=${id}`);
    }

    getParceriaPortalFiltro(campo, valor) {
        // tslint:disable-next-line:max-line-length
        return this.http.get<Array<ParceriaInfo>>(`${environment.serverHost}/secure/${environment.realm}/parcerias/busca?campo=${campo}&texto=${valor}`);
    }

    getPessoa(doc, tipo) {
        return this.http.get<PessoaInfo>(`${environment.serverHost}/secure/${environment.realm}/parcerias/buscaPessoaDocumento?identificacao=${doc}&tipo=${tipo}`);
    }

    getPessoaNome(campo, tipoPesquisa) {
        return this.http.get<Array<PessoaInfo>>(`${environment.serverHost}/secure/${environment.realm}/parcerias/buscaPessoaNome?campo=${campo}&tipoPesquisa=${tipoPesquisa}`);
    }

    updateDespesaParceria(despesa) {
        return this.http.request<HttpResponse<any>>('put', `${environment.serverHost}/secure/${environment.realm}/parcerias/despesa`,
            { observe: 'response', headers: httpOptions.headers, body: despesa });
    }

    updateDespesaParceriaList(despesaList) {
        return this.http.post(`${environment.serverHost}/secure/${environment.realm}/parcerias/despesaList`, despesaList, httpOptions);
    }

    excluirDespesaParceria(despesa) {
        return this.http.request<HttpResponse<any>>('delete', `${environment.serverHost}/secure/${environment.realm}/parcerias/despesa/${despesa.parId}/${despesa.execId}/` + encodeURIComponent(despesa.operacaoUsuario),
            { observe: 'response', headers: httpOptions.headers, body: despesa });
    }


    excluirDevolucaoAplicacao(devolucao) {
        return this.http.request<HttpResponse<any>>('delete', `${environment.serverHost}/secure/${environment.realm}/parcerias/devolucaoAplicacao/${devolucao.parId}/${devolucao.execDevolId}/` + encodeURIComponent(devolucao.operacaoUsuario),
            { observe: 'response', headers: httpOptions.headers, body: devolucao });
    }

    updateDevolucaoAplicacao(devolucao) {
        return this.http.request<HttpResponse<any>>('put', `${environment.serverHost}/secure/${environment.realm}/parcerias/devolucaoAplicacao`,
            { observe: 'response', headers: httpOptions.headers, body: devolucao });
    }

    updateParceria(parceria) {
        return this.http.request<HttpResponse<any>>('put', `${environment.serverHost}/secure/${environment.realm}/parcerias/parceria`,
            { observe: 'response', headers: httpOptions.headers, body: parceria });
    }

    excluirDocComplementar(docComplementar) {
        // tslint:disable-next-line:max-line-length
        return this.http.request<HttpResponse<any>>('delete', `${environment.serverHost}/secure/${environment.realm}/parcerias/parceriaPrestaContalParcial/${docComplementar.parId}/${docComplementar.prestaId}`,
            { observe: 'response', headers: httpOptions.headers, body: docComplementar });
    }

    updateDocComplementar(docComplementar) {
        return this.http.request<HttpResponse<any>>('put', `${environment.serverHost}/secure/${environment.realm}/parcerias/parceriaPrestaContalParcial`,
            { observe: 'response', headers: httpOptions.headers, body: docComplementar });
    }

    excluirPrestacaoFinalConta(doc) {
        // tslint:disable-next-line:max-line-length
        return this.http.request<HttpResponse<any>>('delete', `${environment.serverHost}/secure/${environment.realm}/parcerias/parceriaPrestaContalFinal/${doc.parId}/${doc.prestaFinalId}`,
            { observe: 'response', headers: httpOptions.headers, body: doc });
    }

    updatePrestacaoFinalConta(doc) {
        return this.http.request<HttpResponse<any>>('put', `${environment.serverHost}/secure/${environment.realm}/parcerias/parceriaPrestaContalFinal`,
            { observe: 'response', headers: httpOptions.headers, body: doc });
    }

    excluirMonitoracaoAvaliacao(doc) {
        // tslint:disable-next-line:max-line-length
        return this.http.request<HttpResponse<any>>('delete', `${environment.serverHost}/secure/${environment.realm}/parcerias/parceriaMonitoracaoAvaliacao/${doc.parId}/${doc.monitoraId}`,
            { observe: 'response', headers: httpOptions.headers, body: doc });
    }

    updateMonitoracaoAvaliacao(doc) {
        return this.http.request<HttpResponse<any>>('put', `${environment.serverHost}/secure/${environment.realm}/parcerias/parceriaMonitoracaoAvaliacao`,
            { observe: 'response', headers: httpOptions.headers, body: doc });
    }

    excluirTermo(doc) {
        // tslint:disable-next-line:max-line-length
        return this.http.request<HttpResponse<any>>('delete', `${environment.serverHost}/secure/${environment.realm}/parcerias/parceriaTermo/${doc.parId}/${doc.terId}`,
            { observe: 'response', headers: httpOptions.headers, body: doc });
    }

    updateTermo(doc) {
        return this.http.request<HttpResponse<any>>('put', `${environment.serverHost}/secure/${environment.realm}/parcerias/parceriaTermo`,
            { observe: 'response', headers: httpOptions.headers, body: doc });
    }

    excluirPlanoTrabalho(doc) {
        // tslint:disable-next-line:max-line-length
        return this.http.request<HttpResponse<any>>('delete', `${environment.serverHost}/secure/${environment.realm}/parcerias/parceriaPlanoTrabalho/${doc.parId}/${doc.planoId}`,
            { observe: 'response', headers: httpOptions.headers, body: doc });
    }

    updatePlanoTrabalho(doc) {
        return this.http.request<HttpResponse<any>>('put', `${environment.serverHost}/secure/${environment.realm}/parcerias/parceriaPlanoTrabalho`,
            { observe: 'response', headers: httpOptions.headers, body: doc });
    }

    excluirEditais(doc) {
        // tslint:disable-next-line:max-line-length
        return this.http.request<HttpResponse<any>>('delete', `${environment.serverHost}/secure/${environment.realm}/parcerias/parceriaEditais/${doc.parId}/${doc.ediOrgId}`,
            { observe: 'response', headers: httpOptions.headers, body: doc });
    }

    updateEditais(doc) {
        return this.http.request<HttpResponse<any>>('put', `${environment.serverHost}/secure/${environment.realm}/parcerias/parceriaEditais`,
            { observe: 'response', headers: httpOptions.headers, body: doc });
    }

    excluirValorLiberado(doc) {
        // tslint:disable-next-line:max-line-length
        return this.http.request<HttpResponse<any>>('delete', `${environment.serverHost}/secure/${environment.realm}/parcerias/parceriaValorLiberado/${doc.parId}/${doc.libId}`,
            { observe: 'response', headers: httpOptions.headers, body: doc });
    }
    updateValorLiberado(doc) {
        return this.http.request<HttpResponse<any>>('put', `${environment.serverHost}/secure/${environment.realm}/parcerias/parceriaValorLiberado`,
            { observe: 'response', headers: httpOptions.headers, body: doc });
    }

    updateInfoParceria(dirigenteInfo) {
        return this.http.request<HttpResponse<any>>('put', `${environment.serverHost}/secure/${environment.realm}/parcerias/parceriaInfoDir`,
            { observe: 'response', headers: httpOptions.headers, body: dirigenteInfo });
    }

    updateEmailResponsavelAnali(emailRespAnalise) {
        return this.http.request<HttpResponse<any>>('put', `${environment.serverHost}/secure/${environment.realm}/parcerias/parceriasEmailResponsavelAnali`,
            { observe: 'response', headers: httpOptions.headers, body: emailRespAnalise });
    }

    excluirEmailResponsavelAnali(emailRespAnalise) {
        // tslint:disable-next-line:max-line-length
        return this.http.request<HttpResponse<any>>('delete', `${environment.serverHost}/secure/${environment.realm}/parcerias/parceriasEmailResponsavelAnali/${emailRespAnalise.parId}/${emailRespAnalise.emailRespAnaliseId}`,
            { observe: 'response', headers: httpOptions.headers, body: emailRespAnalise });
    }

    updateMonitoraPrestaExcecaoTrava(monitoraPrestaExcecaoTrava) {
        return this.http.request<HttpResponse<any>>('put', `${environment.serverHost}/secure/${environment.realm}/parcerias/monitoraPrestaExcecaoTrava`,
            { observe: 'response', headers: httpOptions.headers, body: monitoraPrestaExcecaoTrava });
    }

    updateExcecaoTravaAnalise(excecaoTravaAnalise) {
        return this.http.request<HttpResponse<any>>('put', `${environment.serverHost}/secure/${environment.realm}/parcerias/excecaoTravaAnalise`,
            { observe: 'response', headers: httpOptions.headers, body: excecaoTravaAnalise });
    }

    excluirMonitoraPrestaExcecaoTrava(monitoraPrestaExcecaoTrava) {
        // tslint:disable-next-line:max-line-length
        return this.http.request<HttpResponse<any>>('delete', `${environment.serverHost}/secure/${environment.realm}/parcerias/monitoraPrestaExcecaoTrava/${monitoraPrestaExcecaoTrava.parId}/${monitoraPrestaExcecaoTrava.excecaoTravaId}`,
            { observe: 'response', headers: httpOptions.headers, body: monitoraPrestaExcecaoTrava });
    }

    getMonitoraPrestaExcecaoTrava(parId) {
        // tslint:disable-next-line:max-line-length
        return this.http.get<Array<ParMonitoraPrestaExcecaoTrava>>(`${environment.serverHost}/secure/${environment.realm}/parcerias/listaMonitoraPrestaExcecaoTrava?parId=${parId}`);
    }

    getExcecaoTravaAnalise(parId) {
        return this.http.get<Array<ExcecaoTravaAnalise>>(`${environment.serverHost}/secure/${environment.realm}/parcerias/listaExcecaoTravaAnalise?parId=${parId}`);
    }

    buscaEmpenhosPagos(codTipoPes, identPessoa, ano, orgao, empenho, raiz) { 
        // tslint:disable-next-line:max-line-length 
        return this.http.get<Array<ZPagamentoParcerias>>(`${environment.serverHost}/secure/${environment.realm}/parcerias/empenhos?codtipopessoa=${codTipoPes}&identpessoa=${identPessoa}&ano=${ano}&orgao=${orgao}&empenho=${empenho}&raiz=${raiz}`); 
    } 

    excluirGrupoTurma(turma) {
        // tslint:disable-next-line:max-line-length
        return this.http.request<HttpResponse<any>>('delete', `${environment.serverHost}/secure/${environment.realm}/parcerias/gruposTurmas/${turma.parId}/${turma.turmaId}`,
            { observe: 'response', headers: httpOptions.headers, body: turma });
    }

    updateGrupoTurma(turma) {
        return this.http.request<HttpResponse<any>>('put', `${environment.serverHost}/secure/${environment.realm}/parcerias/gruposTurmas`,
            { observe: 'response', headers: httpOptions.headers, body: turma });
    }

    updateExcecaoTravaFrequencia(excecaoTravaFrequencia) {
        return this.http.request<HttpResponse<any>>('put', `${environment.serverHost}/secure/${environment.realm}/beneficiarios/addExcecaoTravaFrequenciaBeneficiario`,
            { observe: 'response', headers: httpOptions.headers, body: excecaoTravaFrequencia });
    }

    excluirExcecaoTravaFrequencia(excecaoTravaFrequencia) {
        // tslint:disable-next-line:max-line-length
        return this.http.request<HttpResponse<any>>('delete', `${environment.serverHost}/secure/${environment.realm}/beneficiarios/deleteExcecaoTravaFrequenciaBeneficiario/${excecaoTravaFrequencia.id}`,
            { observe: 'response', headers: httpOptions.headers, body: excecaoTravaFrequencia });
    }

    getExcecaoTravaFrequencia(parId) {
        // tslint:disable-next-line:max-line-length
        return this.http.get<Array<ExcecaoTravaFrequencia>>(`${environment.serverHost}/secure/${environment.realm}/beneficiarios/listaExcecaoTravaFrequenciaBeneficiario?parId=${parId}`);
    }
    getArquivoDes(parId, execId, tipo, isTmp) {
        // tslint:disable-next-line:max-line-length
        return this.http.get(`${environment.serverHost}/secure/${environment.realm}/parcerias/arquivoDes`, { params: { parId: parId, execId: execId, tipo: tipo, isTmp: isTmp}, observe: 'response', responseType: 'blob' });
    }
    getArquivoDevApl(parId, execDevolId) {
        // tslint:disable-next-line:max-line-length
        return this.http.get(`${environment.serverHost}/secure/${environment.realm}/parcerias/arquivoDevAplic`, { params: { parId: parId, execDevolId: execDevolId, }, observe: 'response', responseType: 'blob' });
    }

    getDespesaParceriaPeriodo(filtroDesembolso) {
        // tslint:disable-next-line:max-line-length
        return this.http.post(`${environment.serverHost}/secure/${environment.realm}/parcerias/despesaMensalPeriodo`, filtroDesembolso, httpOptions);
    }


    getDespesaColaboradorTmp(filtroDesembolso) {
        // tslint:disable-next-line:max-line-length
        return this.http.post(`${environment.serverHost}/secure/${environment.realm}/parcerias/despesaColaboradorTmp`, filtroDesembolso, httpOptions);
    }
    

    getDevolucaoAplicacao(id, mes, ano) {
        // tslint:disable-next-line:max-line-length
        return this.http.get<Array<DevolucaoAplicacao>>(`${environment.serverHost}/secure/${environment.realm}/parcerias/devolucao?id=${id}&mes=${mes}&ano=${ano}`);
    }

    getDevolucaoAplicacaoPeriodo(filtroDevolucao) {
        // tslint:disable-next-line:max-line-length
        return this.http.post(`${environment.serverHost}/secure/${environment.realm}/parcerias/devolucaoPeriodo`, filtroDevolucao, httpOptions);
    }

    getPrestaContaParcial(id, mes, ano) {
        // tslint:disable-next-line:max-line-length
        return this.http.get<Array<ParceriaPrestaContaParcial>>(`${environment.serverHost}/secure/${environment.realm}/parcerias/parceriaPrestaContalParcial?id=${id}&mes=${mes}&ano=${ano}`);
    }

    getPrestaContaParcialPeriodo(filtroDocComplementar) {
        // tslint:disable-next-line:max-line-length
        return this.http.post(`${environment.serverHost}/secure/${environment.realm}/parcerias/parceriaPrestaContalParcialPeriodo`, filtroDocComplementar, httpOptions);
    }

    getPrestaContaFinal(id) {
        // tslint:disable-next-line:max-line-length
        return this.http.get<Array<ParceriaPrestaContaFinal>>(`${environment.serverHost}/secure/${environment.realm}/parcerias/parceriaPrestaContalFinal?id=${id}`);
    }

    getParceriaMonitoraAvalia(id) {
        // tslint:disable-next-line:max-line-length
        return this.http.get<Array<ParceriaMonitoraAvalia>>(`${environment.serverHost}/secure/${environment.realm}/parcerias/parceriaMonitoraAvalia?id=${id}`);
    }

    getArquivo(parId, id, tipo) {
        // tslint:disable-next-line:max-line-length
        return this.http.get(`${environment.serverHost}/secure/${environment.realm}/parcerias/arquivo`, { params: { parId: parId, id: id, tipo: tipo }, observe: 'response', responseType: 'blob'  });
    }
    updateGestor(gest) {
        return this.http.request<HttpResponse<any>>('put', `${environment.serverHost}/secure/${environment.realm}/parcerias/gestor`,
            { observe: 'response', headers: httpOptions.headers, body: gest });
    }
    excluirGestor(gestor) {
        // tslint:disable-next-line:max-line-length
        return this.http.request<HttpResponse<any>>('delete', `${environment.serverHost}/secure/${environment.realm}/parcerias/gestor/${gestor.parId}/${gestor.gestorId}`,
            { observe: 'response', headers: httpOptions.headers, body: gestor });
    }

    getColaborador(id) {
        // tslint:disable-next-line:max-line-length
        return this.http.get<Colaborador>(`${environment.serverHost}/secure/${environment.realm}/parcerias/getColaborador?id=${id}`);
    }

    getColaboradores(cnpjOsc) {
        // tslint:disable-next-line:max-line-length
        return this.http.get<Array<Colaborador>>(`${environment.serverHost}/secure/${environment.realm}/parcerias/colaboradores?cnpjOsc=${cnpjOsc}`);
    }

    getColaboradoresPorNome(cnpjOsc, nome) {
        // tslint:disable-next-line:max-line-length
        return this.http.get<Array<Colaborador>>(`${environment.serverHost}/secure/${environment.realm}/parcerias/colaboradoresPorNome?cnpjOsc=${cnpjOsc}&colaboradorNome=${nome}`);
    }    

    getColaboradoresPorTermo(parId) {
        // tslint:disable-next-line:max-line-length
        return this.http.get<Array<Colaborador>>(`${environment.serverHost}/secure/${environment.realm}/parcerias/colaboradoresPorTermo?parId=${parId}`);
    }

    getArquivoColaborador(colaboradorId,tipoArquivo) {
        // tslint:disable-next-line:max-line-length
        return this.http.get(`${environment.serverHost}/secure/${environment.realm}/parcerias/arquivoColaborador?colaboradorId=${colaboradorId}&tipoArquivo=${tipoArquivo}`, { observe: 'response', responseType: 'blob' });
    }

    updateColaborador(colaborador) {
        // tslint:disable-next-line:max-line-length
        return this.http.request<HttpResponse<any>>('put', `${environment.serverHost}/secure/${environment.realm}/parcerias/colaborador`,
            { observe: 'response', headers: httpOptions.headers, body: colaborador });
    }

    excluirColaborador(doc) {
        // tslint:disable-next-line:max-line-length
        return this.http.request<HttpResponse<any>>('delete', `${environment.serverHost}/secure/${environment.realm}/parcerias/colaborador/${doc.id}`,
            { observe: 'response', headers: httpOptions.headers, body: doc });
    }

    excluirColaboradorCargaHoraria(id) {
        // tslint:disable-next-line:max-line-length
        return this.http.request<HttpResponse<any>>('delete', `${environment.serverHost}/secure/${environment.realm}/parcerias/colaboradorCargaHoraria/${id}`,
            { observe: 'response', headers: httpOptions.headers, body: id });
    }

    consultaNFSe(inscricaoMunicipal, nroDoc) {
        // tslint:disable-next-line:max-line-length
        return this.http.get<NFSe>(`${environment.serverHost}/secure/${environment.realm}/parcerias/consultaNFSe?inscricaoMunicipal=${inscricaoMunicipal}&nroDoc=${nroDoc}`);
    }

    consultaNFSeNacional(chaveAcesso) {
        // tslint:disable-next-line:max-line-length
        return this.http.get<NFSe>(`${environment.serverHost}/secure/${environment.realm}/parcerias/consultaNFSeNacional?chaveDeAcesso=${chaveAcesso}`);
    }

    consultaNFe(chaveDeAcesso, cpfOperador) {
        // tslint:disable-next-line:max-line-length
        return this.http.get<NFe>(`${environment.serverHost}/secure/${environment.realm}/parcerias/consultaNFe?chaveDeAcesso=${chaveDeAcesso}&cpfOperador=${cpfOperador}`);
    }

    getNotificacoes(parId, dataInicial, dataFinal, isFiscal) {
        // tslint:disable-next-line:max-line-length
        return this.http.get<Array<Notificacao>>(`${environment.serverHost}/secure/${environment.realm}/parcerias/notificacoes?parId=${parId}&dataInicial=${dataInicial}&dataFinal=${dataFinal}&isFiscal=${isFiscal}`);
    }

    updateNotificacao(notificacao) {
        // tslint:disable-next-line:max-line-length
        return this.http.request<HttpResponse<any>>('put', `${environment.serverHost}/secure/${environment.realm}/parcerias/notificacao`,
            { observe: 'response', headers: httpOptions.headers, body: notificacao });
    }

    getNotificacoesSininhoOSC(parLogonEmail) {
        // tslint:disable-next-line:max-line-length
        return this.http.get<Array<NotificacaoSininho>>(`${environment.serverHost}/secure/acessopoa/parcerias/notificacoesSininhoOSC?email=${parLogonEmail}`);
    }

    getNotificacoesSininhoFiscal(codOrgao) {
        // tslint:disable-next-line:max-line-length
        return this.http.get<Array<NotificacaoSininho>>(`${environment.serverHost}/secure/pmpa/parcerias/notificacoesSininhoFiscal?codOrgao=${codOrgao}`);
    }

    getNotificacoesTelaOSC(parLogonEmail) {
        // tslint:disable-next-line:max-line-length
        return this.http.get<Array<NotificacaoTela>>(`${environment.serverHost}/secure/acessopoa/parcerias/notificacoesTelaOSC?email=${parLogonEmail}`);
    }

    getNotificacoesTelaFiscal(codOrgao) {
        // tslint:disable-next-line:max-line-length
        return this.http.get<Array<NotificacaoTela>>(`${environment.serverHost}/secure/pmpa/parcerias/notificacoesTelaFiscal?codOrgao=${codOrgao}`);
    }

    novaNotificacao(parId, dataInicial, dataFinal, usuario) {
        // tslint:disable-next-line:max-line-length
        return this.http.get<Notificacao>(`${environment.serverHost}/secure/${environment.realm}/parcerias/novaNotificacao?parId=${parId}&dataInicial=${dataInicial}&dataFinal=${dataFinal}&usuario=${usuario}`);
    }

    getNotificacao(id) {
        // tslint:disable-next-line:max-line-length
        return this.http.get<Notificacao>(`${environment.serverHost}/secure/${environment.realm}/parcerias/notificacao/${id}`);
    }

    ocultaNotifOrgao(id) {
        // tslint:disable-next-line:max-line-length
        return this.http.request<HttpResponse<any>>('delete', `${environment.serverHost}/secure/${environment.realm}/parcerias/ocultaNotifOrgao?id=${id}`,
            { observe: 'response', headers: httpOptions.headers, body: { id: id } });
    }

    ocultaNotifOsc(id) {
        // tslint:disable-next-line:max-line-length
        return this.http.request<HttpResponse<any>>('delete', `${environment.serverHost}/secure/${environment.realm}/parcerias/ocultaNotifOsc?id=${id}`,
            { observe: 'response', headers: httpOptions.headers, body: { id: id } });
    }

    getParametrosAuditoria() {
        // tslint:disable-next-line:max-line-length
        return this.http.get<ParametrosAuditoria>(`${environment.serverHost}/secure/${environment.realm}/parcerias/loadParamAuditoria`);
    }

    updateParametrosAuditoria(parametrosAuditoria) {
        // tslint:disable-next-line:max-line-length
        return this.http.request<HttpResponse<any>>('put', `${environment.serverHost}/secure/${environment.realm}/parcerias/paramAuditoria`,
            { observe: 'response', headers: httpOptions.headers, body: parametrosAuditoria });
    }

    getBuscaDocumentosStatusTotais(campo) {
        // tslint:disable-next-line:max-line-length
        return this.http.post(`${environment.serverHost}/secure/${environment.realm}/parcerias/buscaDocumentosStatusTotais`, campo);
    }

    getPriorizacaoCompleta(campo) {
        // tslint:disable-next-line:max-line-length
        return this.http.post(`${environment.serverHost}/secure/${environment.realm}/parcerias/buscaPriorizacaoCompleta`, campo);
    }

    getCountPriorizacaoCompleta(campo) {
        // tslint:disable-next-line:max-line-length
        return this.http.post(`${environment.serverHost}/secure/${environment.realm}/parcerias/countPriorizacaoCompleta`, campo);
    }

    getVisitacoes(campo) {
        return this.http.post(`${environment.serverHost}/secure/${environment.realm}/parcerias/buscaVisitacao`, campo);
    }

    updateVisitacao(visitacao) {
        // tslint:disable-next-line:max-line-length
        return this.http.request<HttpResponse<any>>('put', `${environment.serverHost}/secure/${environment.realm}/parcerias/visitacao`,
            { observe: 'response', headers: httpOptions.headers, body: visitacao });
    }

    excluirVisitacao(doc) {
        // tslint:disable-next-line:max-line-length
        return this.http.request<HttpResponse<any>>('delete', `${environment.serverHost}/secure/${environment.realm}/parcerias/deleteVisitacao?visitacaoId=${doc.id}`,
            { observe: 'response', headers: httpOptions.headers, body: doc });
    }

    copiaTermo(idOrgao, parNum) {
        // tslint:disable-next-line:max-line-length
        return this.http.get<ParceriaInfo>(`${environment.serverHost}/secure/${environment.realm}/parcerias/copiaTermo?idOrgao=${idOrgao}&parNum=${parNum}`);
    }

    getParceriaByOrgaoParNum(idOrgao, parNum) {
        // tslint:disable-next-line:max-line-length
        return this.http.get<ParceriaInfo>(`${environment.serverHost}/secure/${environment.realm}/parcerias/parceriaByOrgaoParNum?idOrgao=${idOrgao}&parNum=${parNum}`);
    }

    getPrestacoesDeContas(parId) {
        // tslint:disable-next-line:max-line-length
        return this.http.get<Array<PrestacaoDeContas>>(`${environment.serverHost}/secure/${environment.realm}/parcerias/prestacoesContasByParceria?parId=${parId}`);
    }

    deletePrestacaoDeContas(id) {
        // tslint:disable-next-line:max-line-length
        return this.http.delete(`${environment.serverHost}/secure/${environment.realm}/parcerias/prestacaoDeContas?prestacaoId=${id}`,
        {observe: 'response', headers: httpOptions.headers});
    }
  
    processarPrestacaoDeContas(prestacao) {
        // tslint:disable-next-line:max-line-length
        return this.http.request<HttpResponse<any>>('put', `${environment.serverHost}/secure/${environment.realm}/parcerias/processarPrestacaoDeContas`,
            { observe: 'response', headers: httpOptions.headers, body: prestacao });
    }

    getBuscaParceriasPlanilha(busca) {
        // tslint:disable-next-line:max-line-length}
        return this.http.post(`${environment.serverHost}/secure/${environment.realm}/parcerias/buscaPlanilha`, busca, {
            responseType: 'blob',
            headers: new HttpHeaders().append('Content-Type', 'application/json')
        });
    }


    updatePrestacaoDeContas(prestacao) {
        // tslint:disable-next-line:max-line-length
        return this.http.request<HttpResponse<any>>('put', `${environment.serverHost}/secure/${environment.realm}/parcerias/prestacaoDeContas`,
            { observe: 'response', headers: httpOptions.headers, body: prestacao });
    }

    getArquivoPrestacaoDeContas(id) {
        // tslint:disable-next-line:max-line-length
        return this.http.get(`${environment.serverHost}/secure/${environment.realm}/parcerias/arquivoPrestacaoContas?prestacaoId=${id}`, 
            { observe: 'response', responseType: 'blob' });
    }

    getPrestadorServTer(id, isCPF) {
        // tslint:disable-next-line:max-line-length
        return this.http.get<PrestadorServTer>(`${environment.serverHost}/secure/${environment.realm}/parcerias/getPrestadoresServ?id=${id}&isCPF=${isCPF}`);
    }

    getPrestadoresServTerPorOsc(cnpjOsc, isDesembolso, tipoPessoa) {
        // tslint:disable-next-line:max-line-length
        return this.http.get<Array<PrestadorServTer>>(`${environment.serverHost}/secure/${environment.realm}/parcerias/prestadoresServTerPorOSC?cnpjOsc=${cnpjOsc}&isDesembolso=${isDesembolso}&tipoPessoa=${tipoPessoa}`);
    }

    getPrestadoresServTerPorTermo(parId) {
        // tslint:disable-next-line:max-line-length
        return this.http.get<Array<PrestadorServTer>>(`${environment.serverHost}/secure/${environment.realm}/parcerias/prestadoresServTerPorTermo?parId=${parId}`);
    }

    getArquivoPrestadorServTer(prestadorId, contratoId, tipoArq) {
        // tslint:disable-next-line:max-line-length
        return this.http.get(`${environment.serverHost}/secure/${environment.realm}/parcerias/arquivoPorPrestadorServTer?prestadorId=${prestadorId}&contratoId=${contratoId}&tipoArquivo=${tipoArq}`, { observe: 'response', responseType: 'blob' });
    }

    updatePrestadorServTer(prestadoresServTer) {
        // tslint:disable-next-line:max-line-length
        return this.http.request<HttpResponse<any>>('put', `${environment.serverHost}/secure/${environment.realm}/parcerias/prestadorServTer`,
            { observe: 'response', headers: httpOptions.headers, body: prestadoresServTer });
    }

    excluirPrestadorServTer(doc) {
        // tslint:disable-next-line:max-line-length
        return this.http.request<HttpResponse<any>>('delete', `${environment.serverHost}/secure/${environment.realm}/parcerias/delPrestadorServTer?id=${doc.id}&isCPF=${doc.isCPF}`,
            { observe: 'response', headers: httpOptions.headers, body: doc });
    }

    excluirServPrestadoTer(id) {
        // tslint:disable-next-line:max-line-length
        return this.http.request<HttpResponse<any>>('delete', `${environment.serverHost}/secure/${environment.realm}/parcerias/delServPrestadoTer/${id}`,
            { observe: 'response', headers: httpOptions.headers, body: id });
    }    

    excluirPrestadorServTerCargaHoraria(id) {
        // tslint:disable-next-line:max-line-length
        return this.http.request<HttpResponse<any>>('delete', `${environment.serverHost}/secure/${environment.realm}/parcerias/colaboradorCargaHoraria/${id}`,
            { observe: 'response', headers: httpOptions.headers, body: id });
    }
    getReciboPagtoPrestadorServTer(prestadorId, servicoId) {
        // tslint:disable-next-line:max-line-length
        return this.http.get(`${environment.serverHost}/secure/${environment.realm}/parcerias/reciboPagamentoPrestadorServTer?prestadorId=${prestadorId}&servicoId=${servicoId}`, { observe: 'response', responseType: 'blob' });
    }

    insereArquivoDespesaPagtoPess(despesa) {
        return this.http.post(`${environment.serverHost}/secure/${environment.realm}/parcerias/arquivoUploadDespesa`, despesa, httpOptions)
        .pipe(
            timeout(600000), // Timeout de 10 minutos (600.000 ms)
            catchError((error) => {
              if (error.name === 'TimeoutError') {
                console.error('A requisição excedeu o tempo limite de 10 minutos.');
              } else {
                console.error('Erro na requisição:', error);
              }
              return throwError(() => error);
            })
          );
        }

    getTipoDeServico(cpfCnpj: any, isCPF: any, cpfCnpjOsc: any) {
        return this.http.get(`${environment.serverHost}/secure/${environment.realm}/parcerias/getTipoDeServicoPrestador?cpfCnpj=${cpfCnpj}&isCPF=${isCPF}&cpfCnpjOsc=${cpfCnpjOsc}`, {observe: 'response'});
     }

    buscaEmpenhosPagosSigef(identUsuario, emailUsuario, ano, orgao, cnpj, raiz, nroEmpenho) { 
        // tslint:disable-next-line:max-line-length 
        return this.http.get(`${environment.serverHost}/secure/${environment.realm}/parcerias/empenhoSigef?ano=${ano}&identUsuario=${identUsuario}&emailUsuario=${emailUsuario}&orgao=${orgao}
        &cnpj=${cnpj}&raiz=${raiz}&empenho=${nroEmpenho}`); 
    }
    getListaColaboradoAtivoNaOsc(parId) {
        // tslint:disable-next-line:max-line-length
        return this.http.get<Array<Colaborador>>(`${environment.serverHost}/secure/${environment.realm}/parcerias/listaColaboradoAtivoNaOsc?parId=${parId}`);
    }
    
    getListaColaboradoAtivoNaOscPorNome(parId, colaboradorNome) {
        // tslint:disable-next-line:max-line-length
        return this.http.get<Array<Colaborador>>(`${environment.serverHost}/secure/${environment.realm}/parcerias/listaColaboradoAtivoNaOscPorNome?parId=${parId}&colaboradorNome=${colaboradorNome}`);
    }    
    getNomeColaborador(cpf, parId) {
        // tslint:disable-next-line:max-line-length
        return this.http.get(`${environment.serverHost}/secure/${environment.realm}/parcerias/nomeColaborador?cpf=${cpf}&parId=${parId}`, { headers, responseType: 'text' });
    }

    getPrestadorPFeServTer(prestadorId, servicoId) {
        // tslint:disable-next-line:max-line-length
        return this.http.get<PrestadorServTer>(`${environment.serverHost}/secure/${environment.realm}/parcerias/prestadorPFeServTer?prestadorId=${prestadorId}&servicoId=${servicoId}`);
    }


    getIdUltimoServicoIncluido(prestadorId) {
        // tslint:disable-next-line:max-line-length
        return this.http.get<Number>(`${environment.serverHost}/secure/${environment.realm}/parcerias/idUltimoServicoIncluido?prestadorId=${prestadorId}`);
    }


    excluirNotificacao(id) {
        // tslint:disable-next-line:max-line-length
        return this.http.request<HttpResponse<any>>('delete', `${environment.serverHost}/secure/${environment.realm}/parcerias/notificacao/${id}`,
            { observe: 'response', headers: httpOptions.headers, body: id });
    }
    
    excluirNotificacaoItem(id) {
        // tslint:disable-next-line:max-line-length
        return this.http.request<HttpResponse<any>>('delete', `${environment.serverHost}/secure/${environment.realm}/parcerias/notificacaoItem/${id}`,
            { observe: 'response', headers: httpOptions.headers, body: id });
    }
    
}
